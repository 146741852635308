.left-sidebar {
    position: absolute;
    width: 240px;
    height: 100%;
    top: 0px;
    z-index: 20;
    padding-top: 70px;
    background: #EDE7E4;
    /*box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);*/
}
.sidebar-nav {
    background: #EDE7E4;
    padding: 15px;
    height: 100%;
}
.sidebar-nav ul {
    margin: 0px;
    padding: 0px 0px 60px;
}
.sidebar-nav > ul > li {
    margin-bottom: 10px;
    list-style: none;
}
.sidebar-nav > ul > li > ul > li {
    list-style: none;
}
.sidebar-nav ul li ul {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 0;
}
.sidebar-nav ul li.nav-small-cap {
    color: #80AE26;
    font-size: 12px;
    margin-bottom: 0px;
    padding: 14px 14px 14px 20px;
    font-weight: 500;
    list-style: none;
}

.sidebar-nav ul li a {
    color: #717171;
    padding: 8px 35px 8px 20px;
    display: block;
    font-size: 15px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    transition: all 0.1s ease-out;
}
.collapse:not(.show) {
    display: none;
}

.logout .MuiTypography-body1,
.logout .MuiListItemIcon-root {
    color: #a00;
  }
.MuiListItemIcon-root {
    min-width: 40px !important;
}
.MuiTypography-body1 {
    font-size: 0.8rem !important;
    color: #717171;
}