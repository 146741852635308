@media print {
    h1 {
        font-size: 1.5rem !important;
    }
    .topbar{
        display: none !important;
    }
    .left-sidebar{
        display: none !important;
    }
    .page-wrapper{
        margin-left: 0 !important;
        inset: 0px 0px 0px !important;
    }
    .MuiGrid-container {
        display: none !important;
    }
    .MuiTableFooter-root{
        display: none !important;
    }
    .MuiTableCell-root{
        font-size: 0.5rem !important;
    }
    .MuiTableCell-sizeSmall {
        padding: 6px !important;
    }
}