#contentLogin .box {
  position: relative;
  top: 0;
  opacity: 1;
  float: left;
  padding: 60px 50px 40px 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 5;
}

#contentLogin .box.back {
  transform: scale(.95);
  -webkit-transform: scale(.95);
  -ms-transform: scale(.95);
  top: -20px;
  opacity: .8;
  z-index: -1;
}

#contentLogin .box:before {
  content: "";
  width: 100%;
  height: 30px;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  background: rgba(255, 255, 255, .6);
  left: 0;
  transform: scale(.95);
  -webkit-transform: scale(.95);
  -ms-transform: scale(.95);
  z-index: -1;
}

#contentLogin .overbox .title {
  color: #fff;
}

#contentLogin .overbox .title:before {
  background: #fff;
}

#contentLogin .title {
  width: 100%;
  float: left;
  line-height: 46px;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #80AE26;
  position: relative;
}

#contentLogin .title:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -50px;
  background: #80AE26;
}

#contentLogin .input,
#contentLogin .input label,
#contentLogin .input input,
#contentLogin .input .spin,
#contentLogin .button,
#contentLogin .button button .button.login button i.fa,
#contentLogin .material-button .shape:before,
#contentLogin .material-button .shape:after,
#contentLogin .button.login button {
  transition: 300ms cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: 300ms cubic-bezier(.4, 0, .2, 1);
  -ms-transition: 300ms cubic-bezier(.4, 0, .2, 1);
}

#contentLogin .material-button,
#contentLogin .alt-2,
#contentLogin .material-button .shape,
#contentLogin .alt-2 .shape,
#contentLogin .box {
  transition: 400ms cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: 400ms cubic-bezier(.4, 0, .2, 1);
  -ms-transition: 400ms cubic-bezier(.4, 0, .2, 1);
}

#contentLogin .input,
#contentLogin .input label,
#contentLogin .input input,
#contentLogin .input .spin,
#contentLogin .button,
#contentLogin .button button {
  width: 100%;
  float: left;
}

#contentLogin .input,
#contentLogin .button {
  margin-top: 30px;
  height: 70px;
}

#contentLogin .input,
#contentLogin .input input,
#contentLogin .button,
#contentLogin .button button {
  position: relative;
}

#contentLogin .input input {
  height: 3rem;
  border: none;
  background: transparent;
}

#contentLogin .input input,
#contentLogin .input label,
#contentLogin .button button {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

#contentLogin .input:before,
#contentLogin .input .spin {
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}



#contentLogin .input .spin {
  background: #80AE26;
  z-index: 4;
  width: 0;
}

#contentLogin .overbox .input .spin {
  background: rgba(255, 255, 255, 1);
}

#contentLogin .overbox .input:before {
  background: rgba(255, 255, 255, 0.5);
}

#contentLogin .input label {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  cursor: pointer;
  line-height: 60px;
}

#contentLogin .button.login {
  width: 60%;
  left: 20%;
}

#contentLogin .button.login button,
#contentLogin .button button {
  width: 100%;
  line-height: 64px;
  left: 0%;
  background-color: transparent;
  border: 3px solid rgba(0, 0, 0, 0.1);
  font-weight: 900;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.2);
}

#contentLogin .button.login {
  margin-top: 30px;
}

#contentLogin .button {
  margin-top: 20px;
}

#contentLogin .button button {
  background-color: #fff;
  color: #80AE26;
  border: none;
}

#contentLogin .button.login button.active {
  border: 3px solid transparent;
  color: #fff !important;
}

#contentLogin .button.login button.active span {
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
}

#contentLogin .button.login button.active i.fa {
  opacity: 1;
  transform: scale(1) rotate(-0deg);
  -webkit-transform: scale(1) rotate(-0deg);
  -ms-transform: scale(1) rotate(-0deg);
}

#contentLogin .button.login button i.fa {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 60px;
  transform: scale(0) rotate(-45deg);
  -webkit-transform: scale(0) rotate(-45deg);
  -ms-transform: scale(0) rotate(-45deg);
}

#contentLogin .button.login button:hover {
  color: #80AE26;
  border-color: #80AE26;
}

#contentLogin .button {
  margin: 40px 0;
  overflow: hidden;
  z-index: 2;
}

#contentLogin .button button {
  cursor: pointer;
  position: relative;
  z-index: 2;
}

#contentLogin .pass-forgot {
  width: 100%;
  float: left;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
}

#contentLogin .click-efect {
  position: absolute;
  top: 0;
  left: 0;
  background: #80AE26;
  border-radius: 50%;
}

#contentLogin .overbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: inherit;
  border-radius: 10px;
  padding: 60px 50px 40px 50px;
}

#contentLogin .overbox .title,
#contentLogin .overbox .button,
#contentLogin .overbox .input {
  z-index: 111;
  position: relative;
  color: #fff !important;
  display: none;
}

#contentLogin .overbox .title {
  width: 80%;
}

#contentLogin .overbox .input {
  margin-top: 20px;
}

#contentLogin .overbox .input input,
#contentLogin .overbox .input label {
  color: #fff;
}

#contentLogin .overbox .material-button,
#contentLogin .overbox .material-button .shape,
#contentLogin .overbox .alt-2,
#contentLogin .overbox .alt-2 .shape {
  display: block;
}

#contentLogin .material-button,
#contentLogin .alt-2 {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #80AE26;
  position: absolute;
  top: 40px;
  right: -70px;
  cursor: pointer;
  z-index: 100;
  transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
}

#contentLogin .material-button .shape,
#contentLogin .alt-2 .shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#contentLogin .material-button .shape:before,
#contentLogin .alt-2 .shape:before,
#contentLogin .material-button .shape:after,
#contentLogin .alt-2 .shape:after {
  content: "";
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(360deg);
  -webkit-transform: translate(-50%, -50%) rotate(360deg);
  -ms-transform: translate(-50%, -50%) rotate(360deg);
}

#contentLogin .material-button .shape:before,
#contentLogin .alt-2 .shape:before {
  width: 25px;
  height: 4px;
}

#contentLogin .material-button .shape:after,
#contentLogin .alt-2 .shape:after {
  height: 25px;
  width: 4px;
}

#contentLogin .material-button.active,
#contentLogin .alt-2.active {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%) rotate(0deg);
  -webkit-transform: translate(50%, -50%) rotate(0deg);
  -ms-transform: translate(50%, -50%) rotate(0deg);
}

body {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: #a0a0a0;
}

body,
html {
  overflow: hidden;
}

#contentLogin .materialContainer {
  width: 100%;
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  outline: none;
}

#contentLogin .logo{
  text-align: center;
  margin-bottom: 1rem;
}
#contentLogin .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
#contentLogin .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -18px) scale(0.75) !important;
}
#contentLogin .MuiOutlinedInput-input:-webkit-autofill {
  position: initial;
}
#contentLogin .MuiSnackbar-anchorOriginBottomCenter {
  left: 0 !important;
  right: 0 !important;
  transform: translateX(0) !important;
  display: contents !important;
}
#contentLogin .MuiFormControl-marginDense {
  background-color: #80ae2538;
  border-radius: 0.2rem;
}
#contentLogin .active,
#contentLogin .active span {
  
}
#contentLogin .active button {
  background-color: #80ae25 !important;
  color: #fff !important;
  border: 3px solid rgba(0, 0, 0, 0.1);
}
#contentLogin .button.login button:hover {
  color: #fff;
  background-color: #80ae25a3 !important;
  border: 3px solid rgba(0, 0, 0, 0.1);
}