#error{
    width: 100%;
    display: none;
}
#error.active {
    display: block;
}
#error > h4 {
    line-height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;
    font-weight: 400;
    min-height: 3rem;
    background-color: #f55;
    border-radius: 0.3rem 0.3rem 0 0;
    position: relative;
}
#error > h4 > button {
    background-color: #f55;
    border: 1px solid #f55;
    color: #fff;
    position: absolute;
    top: 0.55rem;
    right: 1rem;
}
#errorContent {
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    min-height: 3rem;
    padding: 1rem;
    line-height: 2rem;
}
#errorContent > p {
    border-top: 1px solid #ccc;
}
#errorContent > p:first-child {
    border-top: none;
}