html {
  position: relative;
  min-height: 100%;
  background: #fff;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  background: #fff;
  margin: 0;
  overflow-x: hidden;
}
a {
  color: #7460ee;
  text-decoration: none;
  background-color: transparent;
}
ul ul {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiButton-containedPrimary,
.MuiFab-primary {
  background-color: #96b14a !important
}
.MuiFormLabel-root.Mui-focused,
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #96b14a !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #96b14a !important;
}
input:disabled {
  background-color: #f0f0f0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
