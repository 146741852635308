.topbar {
    position: relative;
    z-index: 50;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
}
.topbar .top-navbar {
    min-height: 70px;
    padding: 0px 15px 0 0;
}
.topbar .top-navbar .navbar-header {
    background: #fff;
}
.topbar .top-navbar .navbar-header img {
    width: auto;
    max-height: 60px;
    margin: 17px 0 17px 20px;
}