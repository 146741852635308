h1 {
    color: #80AE26;
    font-size: 2rem;
    line-height: 30px;
    font-weight: 500;
    margin: 0;
}
h2 {
    color: #80AE26;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    margin: 0;
}
.page-wrapper {
    margin-left: 240px;
    background: #F6EFEA;
    padding: 20px;
}

.MuiInputBase-formControl {
    width: 100%
}